<template>
  <v-list-item
    :key="index"
    :value ="item"
    link
    dense
    class="pa-1"
    :to="item.link"
    v-if="hasAccess"
  >
    <v-list-item-icon>
      <v-icon>{{ item.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content class="ml-n2">
      <v-list-item-title>{{ item.text }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NavigationLinks',
  props: ['item', 'index'],
  data() {
    return {
      hasAccess: false,
    };
  },
  computed: {
    ...mapGetters(['getSession']),
  },
  mounted() {
    this.checkPermissions(this.item.permission);
  },
  methods: {
    ...mapActions(['performGetActions', 'performPostActions']),
    async checkPermissions(permission) {
      switch (permission) {
        case 'register': 
          this.hasAccess = this.checkUserPermission(permission)
          break;
        case 'sms': 
          this.hasAccess = this.checkUserPermission(permission)
          break;
        case 'reports': 
          this.hasAccess = this.checkUserPermission(permission)
          break;
        case 'upload': 
          this.hasAccess = await this.uploadPermission() && this.checkUserPermission(permission);
          break;
        case 'user-management':
          this.hasAccess = this.checkUserPermission(permission)
          break;
        default:
          this.hasAccess = false;
          break;
      }
    },
    async uploadPermission() {
      const fullPayload = {
        endpoint: '/upload-check'
      }
      const data = await this.performGetActions(fullPayload);
      return data;
    },
    checkUserPermission(permission) {
      let result = false;
      switch (this.getSession.role) {
        case 'user': 
          result = permission === 'register' ? true : false;
          break;
        case 'admin': 
          result = true;
          break;
         case 'protocol': 
          result =  permission === 'register' ? true : false;
          break;
        case 'superuser': 
          result = true;
          break;
        default:
          result = false;
          break;
      }
      return result;
    }
  },
};
</script>

<style>

</style>
